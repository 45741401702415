import * as React from 'react';
import styled from 'styled-components';
import { CloseIcon } from './icons/Close';

interface IModalContext {
  modal: IModalSettings;
  show: boolean;
  openModal: (args: IOpenModalArgs) => void;
  hideModal: () => void;
}

interface IModalSettings {
  showCloseIcon?: boolean;
  component?: React.ReactNode;
}
interface IOpenModalArgs {
  settings: IModalSettings;
}

export const ModalContext = React.createContext<IModalContext>({
  modal: {},
  show: false,
  openModal: () => {},
  hideModal: () => {},
});

export const ModalContextProvider: React.FC = ({ children }) => {
  const [modal, setModal] = React.useState<IModalSettings>({});
  const [show, setShow] = React.useState(false);

  const openModal = (args: IOpenModalArgs) => {
    setModal(args.settings);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
    setModal({});
  };

  return (
    <ModalContext.Provider value={{ show, modal, openModal, hideModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const Modal: React.FC = () => {
  const { show, modal, hideModal } = React.useContext(ModalContext);
  if (show && modal?.component) {
    return (
      <StyledModalWrapper>
        {modal.showCloseIcon ? (
          <CloseIcon className="modal-hide-icon" onClick={hideModal} />
        ) : null}
        <StyledModalContent>{modal.component}</StyledModalContent>
        <StyledModalOverlay />
      </StyledModalWrapper>
    );
  }
  return null;
};

const StyledModalContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 40px;
  z-index: 102;
`;

const StyledModalOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 101;
`;

const StyledModalWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;

  .modal-hide-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 103;

    &:hover {
      cursor: pointer;
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }
`;
