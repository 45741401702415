import * as React from 'react';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';

export const PageLoader: React.FC<{ text: string }> = ({ text }) => {
  return (
    <StyledPageLoader>
      <Loader>
        <span
          className="loader-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <BarLoader
          width={150}
          height={4}
          color={'rgb(232,200,147)'}
          loading={true}
        />
      </Loader>
    </StyledPageLoader>
  );
};

const StyledPageLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  user-select: none;
`;

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  span {
    &.loader-text {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 20px;

      @media all and (min-width: 600px) {
        font-size: 18px;
      }
    }
    .top {
      display: block;
    }
    .sub {
      display: block;
      font-size: 14px;
    }
  }
`;
