import * as React from 'react';
import styled from 'styled-components';
import { AlertIcon } from './icons/Alert';
import { CloseIcon } from './icons/Close';

export const MissingData: React.FC<{
  heading: string;
  text?: string;
  size?: 'small';
  onClickClose?: () => void;
}> = ({ heading, text, size, onClickClose }) => {
  if (size === 'small') {
    return (
      <StyledMissingData className="small">
        <div className="top">
          <AlertIcon className="alert-icon" />
          <h2>{heading}</h2>
        </div>
        {text ? (
          <div className="bottom" dangerouslySetInnerHTML={{ __html: text }} />
        ) : null}
        {typeof onClickClose === 'function' ? (
          <CloseIcon onClick={onClickClose} className="close-icon" />
        ) : null}
      </StyledMissingData>
    );
  }

  return (
    <StyledMissingData>
      <div className="top">
        <AlertIcon className="alert-icon" />
        <h2>{heading}</h2>
      </div>
      {text ? (
        <div className="bottom" dangerouslySetInnerHTML={{ __html: text }} />
      ) : null}
    </StyledMissingData>
  );
};

const StyledMissingData = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background: rgb(50, 50, 50);
  padding: 20px;
  color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .top {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 20px;
    width: 100%;
  }

  h2 {
    margin: 0;
  }
  p {
    margin: 10px 0 0 0;
  }
  a {
    color: rgb(232, 200, 147);
  }

  &.small {
    position: relative;
    box-shadow: none;
    padding: 20px 30px;
    &:nth-of-type(even) {
      background: rgb(30, 30, 30);
    }
    h2 {
      font-size: 20px;
    }
    p {
      padding-left: 45px;
      font-size: 14px;
      margin-top: 0;
    }
    .alert-icon {
      width: 25px;
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      margin: auto;

      &:hover {
        cursor: pointer;
      }

      svg {
        width: 15px;

        path {
          fill: #fff;
        }
      }
    }
  }
`;
