import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { PageLoader } from '../containers/PageLoader';
import {
  Query,
  QueryHasOrderArgs,
} from '../../../../client/__types__/generated/types';
import { useParams } from 'react-router';
import { HAS_ORDER_QUERY } from '../../../../client/__apollo__/queries/hasOrder';
import NoMatch from '../containers/NoMatch';

type HasOrderQuery = Pick<Query, 'hasOrder'>;

export const Index: React.FC<any> = ({ children }) => {
  const params = useParams<{ sguid: string; identifier: string }>();
  const location = useLocation();

  const { data, loading, refetch } = useQuery<HasOrderQuery, QueryHasOrderArgs>(
    HAS_ORDER_QUERY,
    {
      ssr: false,
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sguid: params.sguid,
          identifier: params.identifier,
        },
      },
    }
  );

  React.useEffect(() => {
    refetch({
      input: {
        sguid: params.sguid,
        identifier: params.identifier,
      },
    });
  }, [location.pathname]);

  if (loading) {
    return <PageLoader text="Vennligst vent.." />;
  } else if (data?.hasOrder) {
    return children;
  } else {
    return <NoMatch />;
  }
};

export const CheckOrder: React.FC = ({ children }) => {
  return (
    <Route
      exact
      path="/:p1?/:sguid?/:p3?/:identifier?/:p5?/:p6?"
      render={(props) => <Index {...props}>{children}</Index>}
    />
  );
};
