import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

export const PageTransition: React.FC = ({ children }) => {
  const animatedProps = useSpring({
    delay: 100,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return <PageWrapper style={animatedProps}>{children}</PageWrapper>;
};

const PageWrapper = styled(animated.div)`
  width: 100%;
  height: 100%;
`;
