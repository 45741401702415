import { gql } from '@apollo/client';

export const AUTHENTICATED_QUERY = gql`
  query Authenticated($input: AuthenticatedInput!) {
    authenticated(input: $input) {
      loggedIn
      access {
        digital
        print
      }
    }
  }
`;
