import { createGlobalStyle } from 'styled-components';
import { fonts } from './fonts';

export const GlobalStyles = createGlobalStyle`
    ${fonts}

    * {
        box-sizing: border-box;
    }

    body, html, #root {
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
        background: #000;
        color: #fff;
        font-family: 'Proxima-Nova';
        font-size: 16px;
        font-weight: 300;
        word-wrap: break-word;
        line-height: 1.4;
        overflow-y: hidden;
        overflow-x: hidden;

        
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-family: 'DomaineDisp', sans-serif;
        font-weight: 300;
        margin-top: 0;
        color: rgb(232, 200, 147);
    }

    @media all and (max-width: 700px) {
        h1 {
            font-size: 28px;
        }
    }

    button {
        outline-color: rgb(100, 100, 100);
    }
    
    input, textarea, select {
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-family: inherit;
        border: solid 2px transparent;
        box-shadow: 0 0 0px transparent;

        &:focus {
            border: solid 2px rgba(200, 200, 200, 0.5);
            box-shadow: 0 0 5px rgba(100, 100, 100, 0.8);
        }
        
    }
`;
