import * as React from 'react';
import styled from 'styled-components';

interface IAlertIcon {
  className: string;
}

export const AlertIcon: React.FC<IAlertIcon> = ({ className }) => {
  return (
    <StyledIcon className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.76901 3.81094V7.24294C8.76901 7.60694 8.75101 7.96494 8.71501 8.31694C8.67901 8.66494 8.63301 9.03494 8.57701 9.42694H7.56901C7.51301 9.03494 7.46701 8.66494 7.43101 8.31694C7.39501 7.96494 7.37701 7.60694 7.37701 7.24294V3.81094H8.76901ZM7.14301 11.6709C7.14301 11.5469 7.16501 11.4289 7.20901 11.3169C7.25701 11.2049 7.32101 11.1089 7.40101 11.0289C7.48501 10.9489 7.58301 10.8849 7.69501 10.8369C7.80701 10.7889 7.92701 10.7649 8.05501 10.7649C8.17901 10.7649 8.29701 10.7889 8.40901 10.8369C8.52101 10.8849 8.61701 10.9489 8.69701 11.0289C8.78101 11.1089 8.84701 11.2049 8.89501 11.3169C8.94301 11.4289 8.96701 11.5469 8.96701 11.6709C8.96701 11.7989 8.94301 11.9189 8.89501 12.0309C8.84701 12.1389 8.78101 12.2329 8.69701 12.3129C8.61701 12.3929 8.52101 12.4549 8.40901 12.4989C8.29701 12.5469 8.17901 12.5709 8.05501 12.5709C7.92701 12.5709 7.80701 12.5469 7.69501 12.4989C7.58301 12.4549 7.48501 12.3929 7.40101 12.3129C7.32101 12.2329 7.25701 12.1389 7.20901 12.0309C7.16501 11.9189 7.14301 11.7989 7.14301 11.6709Z"
          fill="white"
        />
        <circle
          cx="8"
          cy="8"
          r="7.27273"
          stroke="#E08752"
          strokeWidth="1.45455"
        />
      </svg>
    </StyledIcon>
  );
};

const StyledIcon = styled.div`
  width: 30px;
  height: 30px;

  svg {
    width: inherit;
    height: inherit;
    path {
    }
  }
`;
