import * as React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';

import { IToast } from '../utils/context/ToastContextProvider';
import { CloseIcon } from './icons/Close';

export const Toast: React.FC<Partial<IToast>> = ({
  id,
  type,
  show,
  message,
  onClose,
}) => {
  const [hidden, setHidden] = React.useState(false);
  const animatedProps = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(0, -10px, 0)',
    },
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate3d(0, 0px, 0)' : 'translate3d(0, -10px, 0)',
    },
    onRest: ({ finished, value }) => {
      if (finished && value.opacity === 0) {
        setHidden(true);
      }
    },
  });

  const getClassName = () => {
    if (type === 'success') {
      return 'success';
    } else if (type === 'warning') {
      return 'warning';
    } else if (type === 'alert') {
      return 'alert';
    } else {
      return '';
    }
  };

  return (
    <StyledToast
      style={animatedProps}
      className={hidden ? 'hidden' : getClassName()}
    >
      <span>{message}</span>
      <StyledToastClose
        onClick={() => {
          if (typeof onClose === 'function' && id) {
            onClose(id);
          }
        }}
      >
        <CloseIcon />
      </StyledToastClose>
    </StyledToast>
  );
};

export const ToastWrapper = styled.ul`
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 99;
  pointer-events: none;
`;

const StyledToast = styled(animated.li)`
  display: flex;
  position: relative;
  width: max-content;
  max-width: 600px;
  height: max-content;
  padding: 15px 10px;
  margin: auto;
  z-index: 101;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  pointer-events: auto;

  &.hidden {
    display: none;
  }
  &.success {
    background: #1f7f29;
  }
  &.warning {
    background: orange;
  }
  &.alert {
    background: red;
  }
`;

const StyledToastClose = styled.div`
  margin-left: 10px;

  &:hover {
    cursor: pointer;
  }
`;
