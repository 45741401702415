import * as React from 'react';
import { nanoid } from 'nanoid';
import { Toast, ToastWrapper } from '../../ui/Toast';
import { useLocation } from 'react-router';

type ToastType = 'success' | 'warning' | 'alert';

export interface IToast {
  id: string;
  type: ToastType;
  message: string;
  ignoreExpire?: boolean;
  show: boolean;
  onClose: (id: string) => void;
  hide: Date;
  expire: Date;
}

interface IToastContext {
  items: Partial<IToast>[];
  addToast: (item: Pick<IToast, 'type' | 'message' | 'ignoreExpire'>) => void;
}

export const ToastContext = React.createContext<IToastContext>({
  items: [],
  addToast: () => {},
});

interface IToastContextProvider {
  maxItems: number;
}

export const ToastContextProvider: React.FC<IToastContextProvider> = ({
  maxItems = 3,
  children,
}) => {
  const location = useLocation();
  const [items, setItems] = React.useState<Partial<IToast>[]>([]);

  const removeToast = (id: string) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  };

  const reset = () => {
    setItems([]);
  };

  const addToast = (
    item: Pick<IToast, 'type' | 'message' | 'ignoreExpire'>
  ) => {
    const id = nanoid();
    let dateHide = new Date();
    dateHide.setSeconds(dateHide.getSeconds() + 10);
    let dateExpire = new Date();
    dateExpire.setSeconds(dateExpire.getSeconds() + 15);
    const obj: Partial<IToast> = {
      ...item,
      id: id,
      onClose: removeToast,
      show: true,
      hide: dateHide,
      ignoreExpire: item.ignoreExpire || false,
      expire: dateExpire,
    };
    setItems((prev) => [...prev, obj]);
  };

  const updateToast = (id: string, value: Pick<IToast, 'show'>) => {
    setItems((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, ...value };
        }
        return item;
      })
    );
  };

  /* Remove first item in array */
  React.useEffect(() => {
    if (items.length > maxItems) {
      let copy = [...items];
      copy.shift();
      setItems(copy);
    }
  }, [items]);

  React.useEffect(() => {
    reset();
  }, [location.pathname]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      items.map((item) => {
        if (item && item.hide) {
          const hide = new Date(item.hide);
          const now = new Date();
          if (hide < now && !item.ignoreExpire) {
            if (item.id) {
              updateToast(item.id, { show: false });
            }
          }
        }
        if (item && item.expire && !item.ignoreExpire) {
          const expire = new Date(item.expire);
          const now = new Date();
          if (expire < now) {
            if (item.id) {
              removeToast(item.id);
            }
          }
        }
      });
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [, items]);

  return (
    <ToastContext.Provider value={{ items, addToast }}>
      {children}

      <ToastWrapper>
        {items.map((item) => (
          <Toast
            key={item.id}
            id={item.id}
            type={item.type}
            show={item.show}
            message={item.message}
            onClose={item.onClose}
          />
        ))}
      </ToastWrapper>
    </ToastContext.Provider>
  );
};
