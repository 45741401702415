import * as React from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';

import { GlobalStyles } from '../client/styles/GlobalStyles';

import { Protected } from './components/admin/hoc/Protected';
import { CheckOrder } from './components/admin/hoc/CheckOrder';
import { Modal, ModalContextProvider } from './components/admin/ui/Modal';
import { ToastContextProvider } from './components/admin/utils/context/ToastContextProvider';
import { CheckEstateType } from './components/admin/hoc/CheckEstateType';

const SelectType = loadable(
  () => import('./components/admin/containers/Index')
);
const Settings = loadable(
  () => import('./components/admin/containers/Settings')
);
const NoMatch = loadable(() => import('./components/admin/containers/NoMatch'));
const NoAccess = loadable(
  () => import('./components/admin/containers/NoAccess')
);
const PreviewNotAvailable = loadable(
  () => import('./components/admin/containers/PreviewNotAvailable')
);
const EstateTypeNotAvailable = loadable(
  () => import('./components/admin/containers/EstateTypeNotAvailable')
);
const Posted = loadable(() => import('./components/admin/containers/Posted'));
const Orders = loadable(() => import('./components/admin/containers/Orders'));
const CompleteOrder = loadable(
  () => import('./components/admin/containers/CompleteOrder')
);
const Preview = loadable(() => import('./components/admin/containers/Preview'));

export const App: React.FC = () => {
  return (
    <>
      <GlobalStyles />
      <Route
        exact
        path="/preview-not-available"
        component={PreviewNotAvailable}
      />
      <Route exact path="/no-access" component={NoAccess} />
      <Route
        exact
        path="/estate-type-not-valid"
        component={EstateTypeNotAvailable}
      />
      <Protected>
        <ToastContextProvider maxItems={3}>
          <ModalContextProvider>
            <Modal />
            <Switch>
              <Route exact path="/approval/:sguid" component={SelectType} />
              <CheckEstateType>
                <Route exact path="/approval/:sguid/orders" component={Orders} />
                <CheckOrder>
                  <Switch>
                    <Route
                      exact
                      path="/approval/:sguid/order/:identifier/settings"
                      component={Settings}
                    />
                    <Route
                      exact
                      path="/approval/:sguid/order/:identifier/preview"
                      component={Preview}
                    />
                    <Route
                      exact
                      path="/approval/:sguid/order/:identifier/complete"
                      component={CompleteOrder}
                    />
                    <Route
                      exact
                      path="/approval/:sguid/order/:identifier/posted"
                      component={Posted}
                    />
                  </Switch>
                </CheckOrder>
              </CheckEstateType>
              
              <Route exact path="/page-not-found" component={NoMatch} />
              <Route component={NoMatch} />
            </Switch>
          </ModalContextProvider>
        </ToastContextProvider>
      </Protected>
    </>
  );
};
