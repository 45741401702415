import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { ApolloProvider } from '@apollo/client';

import { App } from '../common/App';
import { client } from './__apollo__/client';

const Client: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
};

loadableReady(() => {
  const mount = document.getElementById('root');
  ReactDOM.render(<Client />, mount);
});
