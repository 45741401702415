import * as React from 'react';
import styled from 'styled-components';
import { MissingData } from '../ui/MissingData';

export const PageError: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <StyledPage>
      <MissingData
        heading={text as string}
        text={`<p>Vedvarer feilen? Kontakt helpdesk:
        <a
          href="mailto:helpdesk@reeltime.no"
        >
          helpdesk@reeltime.no
        </a></p>`}
      />
    </StyledPage>
  );
};

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
`;

const StyledPageMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: rgb(200, 0, 0);

  .error-message {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
  }

  a {
    color: #fff;
  }
`;
