import fetch from 'cross-fetch';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AUTHENTICATED_QUERY } from './queries/authenticated';

declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, extensions, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (extensions?.code === '401' || extensions?.code === '403') {
        const { cache } = operation.getContext();
        cache.reset();
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = createHttpLink({
  uri: window.environment.API_URL,
  fetch: fetch,
  credentials: 'include',
});

const cache = new InMemoryCache();
if (typeof window !== 'undefined') {
  cache.restore(window.__APOLLO_STATE__);
}

export const client = new ApolloClient({
  name: 'privatmegleren_digital_salgsoppgave_client',
  link: ApolloLink.from([errorLink, link]),
  cache: cache,
});
