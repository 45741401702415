import { css } from 'styled-components';

export const fonts = css`
  @font-face {
    font-family: 'DomaineDisp';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisplayWeb-Regular.woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'DomaineDisp';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisp-Semibold.woff'),
      url('https://cdn.reeltime.no/pm_assets/fonts/DomaineDisp-Semibold.otf')
        format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Proxima-Nova';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/ProximaNova-Reg.woff'),
      url('https://cdn.reeltime.no/pm_assets/fonts/ProximaNova-Reg.otf')
        format('opentype');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Proxima-Nova';
    src: url('https://cdn.reeltime.no/pm_assets/fonts/ProximaNova-Sbold.woff'),
      url('https://cdn.reeltime.no/pm_assets/fonts/ProximaNova-Sbold.otf')
        format('opentype');
    font-weight: bold;
  }
`;
