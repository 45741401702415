import { gql } from '@apollo/client';

export const GET_STATUS_QUERY = gql`
  query GetStatus($input: StatusInput!) {
    status(input: $input) {
      validAssigmentType
      dsExist
      soExist
    }
  }
`;
