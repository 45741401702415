import * as React from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';
import { PageLoader } from '../containers/PageLoader';
import {
  Query,
  QueryHasOrderArgs,
  QueryStatusArgs,
} from '../../../../client/__types__/generated/types';
import { useParams } from 'react-router';
import NoMatch from '../containers/NoMatch';
import { GET_STATUS_QUERY } from '../../../../client/__apollo__/queries/getStatus';
import EstateTypeNotAvailable from '../containers/EstateTypeNotAvailable';


type GetStatusQuery = Pick<Query, 'status'>;
type HasDsVariables = QueryStatusArgs;

export const Index: React.FC<any> = ({ children }) => {
  const params = useParams<{ sguid: string; identifier: string }>();
  const location = useLocation();

  const { data, loading, refetch } = useQuery<GetStatusQuery, HasDsVariables>(
    GET_STATUS_QUERY,
    {
      fetchPolicy: 'network-only',
      ssr: false,
      variables: {
        input: {
          sguid: params.sguid,
        },
      },
    }
  );


  React.useEffect(() => {
    refetch({
      input: {
        sguid: params.sguid
      },
    });
  }, [location.pathname]);

  if (loading) {
    return <PageLoader text="Sjekker eiendomstype.." />;
  } else if (data?.status?.validAssigmentType) {
    return children;
  } else {
    return <EstateTypeNotAvailable />;
  }
};

export const CheckEstateType: React.FC = ({ children }) => {
  return (
    <Route
      exact
      path="/:p1?/:sguid?/*"
      render={(props) => <Index {...props}>{children}</Index>}
    />
  );
};
