import * as React from 'react';
import styled from 'styled-components';
import { PageTransition } from '../../common/layout/PageTransition';
import { MissingData } from '../ui/MissingData';

const EstateTypeNotAvailable: React.FC = () => {
  return (
    <PageTransition>
      <Page>
        <MissingData
          heading="Denne oppdragstypen er ikke godkjent for trykk"
          text="<p>Har du spørsmål? Send dette til <a href='mailto:helpdesk@reeltime.no'>helpdesk@reeltime.no</a></p>"
        />
      </Page>
    </PageTransition>
  );
};

export default EstateTypeNotAvailable;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 30px;
`;
