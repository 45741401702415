import * as React from 'react';
import styled from 'styled-components';
import { PageTransition } from '../../common/layout/PageTransition';

const NoMatch: React.FC = () => {
  return (
    <PageTransition>
      <Page>
        <h2>Finner ikke siden du leter etter</h2>
        <p>Er du sikker på at du skrev riktig?</p>
      </Page>
    </PageTransition>
  );
};

export default NoMatch;

const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 30px;

  h2 {
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
  }
`;
