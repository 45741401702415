import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { AUTHENTICATED_QUERY } from '../../../../client/__apollo__/queries/authenticated';

import { PageLoader } from '../containers/PageLoader';
import {
  Query,
  QueryAuthenticatedArgs,
} from '../../../../client/__types__/generated/types';

import { PageError } from '../containers/PageError';

type AuthenticatedQuery = Pick<Query, 'authenticated'>;
type AuthenticatedQueryVariables = QueryAuthenticatedArgs;

export const Protect: React.FC = React.memo(({ children }) => {
  const params = useParams<{ p2?: string; p3?: string }>();
  const { data, loading } = useQuery<
    AuthenticatedQuery,
    AuthenticatedQueryVariables
  >(AUTHENTICATED_QUERY, {
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        sguid: params?.p2,
      },
    },
  });

  if (loading) {
    return <PageLoader text="Sjekker tilgang. Vennligst vent.." />;
  }

  if (data?.authenticated?.loggedIn) {
    return <>{children}</>;
  } else {
    return <PageError text="Du er ikke innlogget i Webmegler." />;
  }
});

export const Protected: React.FC = ({ children }) => {
  return (
    <Route
      exact
      path="/:p1?/:p2?/:p3?/:p4?/:p5?/:p6?"
      render={(props) => <Protect {...props}>{children}</Protect>}
    />
  );
};
